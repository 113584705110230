import { fabric } from "fabric";
import { Box, Coord } from "../boundaries";

/**
 * Note: There is some overlap with components/tools/FitToViewport
 *				Would be good to consolidate these together for ways to fit to boundaries (including viewport)
 */

export type BoxCoord = {
  readonly tl: Coord | fabric.Point;
  readonly tr: Coord | fabric.Point;
  readonly bl: Coord | fabric.Point;
  readonly br: Coord | fabric.Point;
};

export type Bounding = {
  readonly top: number;
  readonly left: number;
  readonly width: number;
  readonly height: number;
};

// type ActFunction = (a: number, b: number) => number;

// /**
//  * Terrible function, don't use
//  */
// const compareAndActWithZero = (
// 	a: number | undefined,
// 	b: number | undefined,
// 	func: ActFunction
// ): number => {
// 	if (!a) {
// 		if (!b) {
// 			return 0;
// 		} else {
// 			return b;
// 		}
// 	} else if (!b) {
// 		return a;
// 	}

// 	return func(a, b);
// };

// /**
//  * Terrible function, don't use
//  */
// const compareAndActWithOne = (
// 	a: number | undefined,
// 	b: number | undefined,
// 	func: ActFunction
// ): number => {
// 	if (!a) {
// 		if (!b) {
// 			return 1;
// 		} else {
// 			return b;
// 		}
// 	} else if (!b) {
// 		return a;
// 	}

// 	return func(a, b);
// };

// export const calculatePan = (box1: BoxMatrix) => (box2: BoxMatrix): Coord => ({
// 	x: compareAndActWithZero(box1[0], box2[0], (a, b) => a + b),
// 	y: compareAndActWithZero(box1[1], box2[1], (a, b) => a + b),
// });

// /**
//  * Calculate the PanZoom for the 2 Box's
//  */
// export const calculatePanZoom = (objectsBox: BoxMatrix) => (
// 	canvasBox: BoxMatrix
// ) => ({ width, height }: Bounding): PanZoom => ({
// 	pan: calculatePan(objectsBox)(canvasBox),
// 	zoom: compareAndActWithOne(objectsBox[2], objectsBox[3], (a, b) =>
// 		Math.min(width / a, height / b)
// 	),
// });

export const middlePoint = (width: number, height: number): fabric.Point =>
  new fabric.Point(width / 2, height / 2);

/**
 * Transform Box to Bounding
 */
export const toBounding = (box: Box): Bounding => ({
  left: box[0],
  top: box[1],
  width: box[0] + box[2],
  height: box[1] + box[3],
});

export const boundingToBox = ({ left, top, width, height }: Bounding): Box => [
  left,
  top,
  left + width,
  top + height,
];

/**
 * Transform a BoxCoord to a Box
 */
export const toBox = ({ tl, br }: BoxCoord): Box => [tl.x, tl.y, br.x, br.y];

// /**
//  * Find the bounding Box of the Canvas
//  */
// export const canvasBoundingBox = (canvas: fabric.Canvas): Box => {
// 	const width = canvas.getWidth();
// 	const height = canvas.getHeight();

// 	if (!canvas.viewportTransform) {
// 		return [0, 0, width, height];
// 	}

// 	const { translateX, translateY } = fabric.util.qrDecompose(
// 		canvas.viewportTransform
// 	);

// 	return [translateX, translateY, translateX + width, translateY + height];
// };

// /**
//  * Get the tl<fabric.Point>, br<fabric.Point> corners of the fabric.Canvas objects.
//  */
// export const objectsBoundingBox = (canvas: fabric.Canvas): BoxMatrix =>
// 	boundingToBox(new fabric.Group(canvas.getObjects()).getBoundingRect());

// /**
//  * Get the tl<fabric.Point>, br<fabric.Point> corners of the fabric.Canvas objects.
//  */
// export const findObjectsBoundingBox = (canvas: fabric.Canvas): BoxMatrix =>
// 	canvas
// 		.getObjects()
// 		.map(o => (o.oCoords ? toBox(o.oCoords) : emptyBoxMatrix()))
// 		.reduce((acc: BoxMatrix, box: BoxMatrix): BoxMatrix => {
// 			return !box ? acc : bounding(acc)(box);
// 		}, emptyBoxMatrix());

// /**
//  * Find the offset of the 2 points.
//  * Useful for offset from origin.
//  */
// export const offset = (a: fabric.Point) => (
// 	b: fabric.Point
// ): readonly [number, number] => [a.x - b.x, a.y - b.y];

// /**
//  * Calculate the movement of the canvas viewport to the bounding box.
//  */
// export const calculateCanvasMovement = (
// 	box: Bounding,
// 	{ height }: { readonly height: number }
// ): Coord => {
// 	const bottom = box.top + box.height;
// 	const bottomDiff = height - bottom;

// 	if (box.top < 90) {
// 		return {
// 			x: 0,
// 			y: 90 + box.top,
// 		};
// 	}

// 	if (bottomDiff < 100 + 16) {
// 		return {
// 			x: 0,
// 			y: bottomDiff - 100 - 16,
// 		};
// 	}

// 	return { x: 0, y: 0 };
// };
