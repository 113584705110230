/* eslint-disable max-lines */
import { fabric } from "fabric";
import { PIXELS_PER_FEET, PIXELS_PER_METER } from "../objects";
import {
  inBounds,
  Meters,
  metersPerPixelToOSMZoomLevel,
  OSMZoomLevel,
  OSMZoomLevelToMetersPerPixel,
  zoomOutOfBounds,
} from "../zoom";
import { middlePoint } from "./boundaries";

/**
 * Zoom to point event
 */
type ZoomTo = { readonly point: fabric.Point; readonly level: number };

export type ZoomFabricEventHandlers<T> = {
  on(eventName: "zoomto", handler: (event: ZoomFabricEvent) => void): T;

  on(events: {
    readonly [eventName: string]: (event: ZoomFabricEvent) => void;
  }): T;
};

/**
 * Add new level, point variables to fabric.IEvent to handle zoom events
 */
export interface ZoomFabricEvent extends fabric.IEvent {
  readonly point?: { readonly x: number; readonly y: number };
  readonly level?: number;
  readonly self?: {
    readonly fingers: number;
    readonly gesture: string;
    readonly start: { readonly x: number; readonly y: number };
    readonly state: string;
    readonly target: HTMLElement | null;
    readonly bbox: {
      readonly height: number;
      readonly scaleX: number;
      readonly scaleY: number;
      readonly scrollBodyLeft: number;
      readonly scrollBodyTop: number;
      readonly scrollLeft: number;
      readonly scrollTop: number;
      readonly width: number;
      readonly x1: number;
      readonly x2: number;
      readonly y1: number;
      readonly y2: number;
    };
    readonly scale: number;
    readonly x: number;
    readonly y: number;
    readonly rotation: number;
  }; // Event proxy object by Event.js
}

/**
 * Zoom by level to point on the canvas
 */
export const zoomToPoint =
  (canvas: Readonly<fabric.StaticCanvas>) =>
  (viewportPoint: Readonly<fabric.Point>) =>
  (fabricZoomLevel: number): ZoomTo => {
    const zoom = canvas.getZoom();

    fabric.util.animate({
      startValue: zoom,
      endValue: fabricZoomLevel,
      duration: 96,
      easing: fabric.util.ease.easeInOutQuad,
      onChange: (endValue) => {
        canvas.zoomToPoint(viewportPoint, endValue);
        canvas.fire("zoomto", { point: viewportPoint, level: fabricZoomLevel });
        canvas.requestRenderAll();
      },
      onComplete: () => {
        canvas
          .getObjects()
          .map((object: Readonly<fabric.Object>) => object.setCoords());
        canvas.requestRenderAll();
      },
    });

    /* see {@link https://github.com/kangax/fabric.js/wiki/When-to-call-setCoords|When-to-call-setCoords} */

    return { point: viewportPoint, level: fabricZoomLevel };
  };

/**
 * Numeric curry function
 */
type MathFunction = (a: number) => (b: number) => number;

export const add: MathFunction = (a) => (b) => a + b;
export const subtract: MathFunction = (a) => (b) => a - b;

/**
 * Calculate fabric zoom level using a math function method
 */
export const calculateNewZoomLevel =
  (method: MathFunction) =>
  (osmZoomLevel: OSMZoomLevel): OSMZoomLevel => {
    const newLevel = method(osmZoomLevel)(1);
    if (zoomOutOfBounds(newLevel)) {
      return inBounds(osmZoomLevel);
    }

    return newLevel;
  };

/**
 * Add point/level interface for handing zoom trigger events
 */
export const zoomToMiddle =
  (canvas: fabric.StaticCanvas) =>
  (fabricZoomLevel: number): ZoomTo => {
    const mid = middlePoint(canvas.getWidth(), canvas.getHeight());

    // const mid = canvas.getVpCenter()

    return zoomToPoint(canvas)(mid)(fabricZoomLevel);
  };

/**
 * Zoom into the fabric canvas
 */
export const zoomIn =
  (canvas: fabric.StaticCanvas) =>
  (fabricZoomLevel: number): number => {
    canvas.setZoom(fabricZoomLevel);
    canvas.fire("zoomin", fabricZoomLevel);

    return fabricZoomLevel;
  };

/**
 * Zoom out from the fabric canvas
 */
export const zoomOut =
  (canvas: fabric.StaticCanvas) =>
  (fabricZoomLevel: number): number => {
    canvas.setZoom(fabricZoomLevel);
    canvas.fire("zoomout", fabricZoomLevel);

    return fabricZoomLevel;
  };

export const metersPerPixelToFabricZoomLevel = (
  meters: Meters
): FabricZoomLevel => {
  return (1 / meters) * 0.012_191_999_600_000_001;
};

/**
 * Convert OSM zoom level to fabric zoom level
 */
export const OSMZoomLevelToFabricZoomLevel =
  (latitude: number) =>
  (osmZoomLevel: OSMZoomLevel): FabricZoomLevel => {
    const metersPerPixel = OSMZoomLevelToMetersPerPixel(latitude)(osmZoomLevel);
    return metersPerPixelToFabricZoomLevel(metersPerPixel);
  };

/**
 * Convert fabric zoom level to OSM zoom level
 */
export const fabricZoomLevelToOSMZoomLevel =
  (latitude: number) =>
  (fabricZoomLevel: FabricZoomLevel): number => {
    const metersPerPixel = fabricZoomLevelToMetersPerPixel(fabricZoomLevel);
    return metersPerPixelToOSMZoomLevel(latitude)(metersPerPixel);
  };

export type FabricZoomLevel = number;

export const fabricZoomLevelToFeetPerPixel = (
  fabricZoomLevel: FabricZoomLevel
): number => {
  return 1 / fabricZoomLevel / PIXELS_PER_FEET;
};

export const fabricZoomLevelToMetersPerPixel = (
  fabricZoomLevel: FabricZoomLevel
): number => {
  return 1 / fabricZoomLevel / PIXELS_PER_METER;
};
