import { fabric } from "fabric";
import log from "../log";

/**
 * Translate the viewport transform over the implied offset.
 * Used in the background grid.
 */
export const translate =
  ([x, y]: readonly [number, number]) =>
  (transform: readonly number[]): readonly [number, number] => {
    if (!transform) {
      return [x, y];
    }

    const { translateX, translateY } = fabric.util.qrDecompose([...transform]);

    return [x + translateX, y + translateY];
  };

/**
 * Update DOM body grid background based on the fabric viewport transform
 */
// eslint-disable-next-line functional/prefer-readonly-type
export const updateGridBackground = (viewport: readonly number[]): void => {
  updateGridBackgroundPosition([-1, -2])(viewport);
  updateGridBackgroundSize(viewport[3]);

  log.trace(
    `Updated grid background ${viewport[3]} ${viewport[4]} ${viewport[5]}`
  );
};

/**
 * Update DOM body background position [side-effect]
 */
export const updateGridBackgroundPosition =
  ([offsetX, offsetY]: readonly [number, number]) =>
  // eslint-disable-next-line functional/prefer-readonly-type
  (transform: readonly number[]): void => {
    const [x, y] = translate([offsetX, offsetY])(transform);

    // eslint-disable-next-line functional/immutable-data, fp/no-mutation
    document.body.style.backgroundPosition = [...Array.from({ length: 5 })]
      .map(() => `${x}px ${y}px`)
      .join(", ");
  };

/**
 * Update body grid background size
 */
export const updateGridBackgroundSize = (level: number): void => {
  const inner = 25;
  const outer = 100;

  const innerSize = inner * level;
  const outerSize = outer * level;

  if (outerSize > 100 || outerSize < 30) {
    // eslint-disable-next-line functional/immutable-data, fp/no-mutation
    document.body.style.backgroundSize = `${outerSize}px ${outerSize}px, ${outerSize}px ${outerSize}px, ${innerSize}px ${innerSize}px, ${innerSize}px ${innerSize}px`;
  } else {
    document.body.style.backgroundSize = `${outerSize}px ${outerSize}px`;
  }

  if (outerSize < 40) {
    document.body.style.backgroundImage = `linear-gradient(
				var(--accent-color2-20) 2px,
				transparent 2px
			),
			linear-gradient(90deg, transparent 2px, transparent 2px),
			linear-gradient(transparent 1px, transparent 1px),
			linear-gradient(90deg, transparent 1px, transparent 1px)`;
  } else if (outerSize < 15) {
    document.body.style.backgroundImage = "none";
  } else {
    document.body.style.backgroundImage = `linear-gradient(
      var(--accent-color2-60) 2px,
      transparent 2px
    ),
    linear-gradient(90deg, var(--accent-color2-60) 2px, transparent 2px),
    linear-gradient(var(--accent-color2-20) 1px, transparent 1px),
    linear-gradient(90deg, var(--accent-color2-20) 1px, transparent 1px)`;
  }
};
