import { fabric } from "fabric";

// static variable but there is a change option to see when it changes.
// Right now we don't change the colorscheme in real-time so getting initial value may be ok.
// The user can reload the page to get a new colorscheme
const prefersDarkColorscheme =
  "matchMedia" in window &&
  window.matchMedia("(prefers-color-scheme: dark)").matches;

export const dropShadow = (): fabric.Shadow => {
  return new fabric.Shadow({
    // offsetX: 1,
    offsetY: 1,
    blur: 1,
    nonScaling: true,
    color: prefersDarkColorscheme ? "black" : "white",
  });
};

/**
 * Remove shadows from a fabric object
 * **Animated**
 */
export const removeShadows = (object: fabric.Object): void => {
  if (typeof object.shadow === "string") {
    return;
  }

  if (object.type === "textbox") {
    // Enable shadows for text
    object.set("shadow", dropShadow());
  }

  // If we have a shadow with blur, remove it
  if (object.shadow?.blur && object.shadow?.blur > 1) {
    object.animate(
      { "shadow.blur": 1 },
      {
        duration: 48,
        easing: fabric.util.ease.easeInOutCubic,
        onChange: () => object.canvas?.requestRenderAll(),
        onComplete: () => {
          object.set("shadow", "");
          object.canvas?.requestRenderAll();
        },
      }
    );
  }
};

export const glowAura = () =>
  new fabric.Shadow({ color: "gold", nonScaling: true });

/**
 * Add a glow around a fabric object
 * **Animated**
 */
export const applyGlow = (object: fabric.Object): void => {
  object.set("shadow", glowAura());
  object.animate(
    { "shadow.blur": 50 },
    {
      duration: 96,
      easing: fabric.util.ease.easeInOutCubic,
      onChange: () => object.canvas?.requestRenderAll(),
    }
  );
};
