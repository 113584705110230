/* eslint-disable max-lines */
import { fabric } from "fabric";
import {
	getFillFile,
	getShapeSVGFile,
	isCustomShape,
	isPath,
	isLineTool,
	isTextbox,
	Shape,
	isImage,
} from "../../shapes";
import { calculateOffsetMovement, Direction } from "../direction";
import { loadPatternFill } from "../fabric";
import log from "../log";
import * as ecogardenObjects from "../objects";
import { loadImageDB, loadImageFromStorage, setupImage } from "../image";
import {
	EcogardenLine,
	EcogardenObject,
	EcogardenObjects,
	EcogardenPath,
	EcogardenPolygon,
	EcogardenTextbox,
	PIXELS_PER_FEET,
	sizeInPixelsToScale,
} from "../objects";
import { loadShape } from "../objects/svg";
import { capitalize, dashesToSpaces } from "../string";
import type { Bounding } from "./boundaries";
import { createPolygon, createTextbox } from "./shapes";
import { applyControls as applyLineControls } from "../controls/fabric/line";
import { applyControls as applyPolygonControls } from "../controls/fabric/polygon";
import { createPath, defaultPathOpts } from "../objects/freepath";
import { sizeToNumber } from "../text";
import pathUtil, { sizeToNumber as pathSizeToNumber } from "../path";

export type EcogardenFabricObjects =
	| EcogardenFabricObject
	| EcogardenFabricGroup
	| EcogardenFabricPolygon
	| EcogardenFabricRect
	| EcogardenFabricTextbox
	| EcogardenFabricPath
	| EcogardenFabricCircle
	| EcogardenFabricLine
	| EcogardenFabricImage;

export interface EcogardenFabricObjectOptions {
	// eslint-disable-next-line functional/prefer-readonly-type
	id?: string;
	// eslint-disable-next-line functional/prefer-readonly-type
	subtype?: Shape | string;
	// eslint-disable-next-line functional/prefer-readonly-type
	label?: string;
}

export interface EcogardenFabricRect
	extends fabric.Rect,
		EcogardenFabricObjectOptions {}

export interface EcogardenFabricCircle
	extends fabric.Circle,
		EcogardenFabricObjectOptions {}

export interface EcogardenFabricObject
	extends fabric.Object,
		EcogardenFabricObjectOptions {}

export interface EcogardenFabricGroup
	extends fabric.Group,
		EcogardenFabricObjectOptions {
	type: "group";
	_objects: fabric.Object[];
	getObjects: () => fabric.Object[];
}

export interface EcogardenFabricPolygon
	extends fabric.Polygon,
		EcogardenFabricObjectOptions {
	/**
	 * Polygon points
	 */
	// eslint-disable-next-line functional/prefer-readonly-type
	readonly points?: (fabric.Point | { x: number; y: number })[];
	readonly type?: "polygon";
}

export interface EcogardenFabricPath
	extends fabric.Path,
		EcogardenFabricObjectOptions {
	readonly pathOffset: fabric.Point | { x: number; y: number };
}

export interface EcogardenFabricTextbox
	extends fabric.IText,
		EcogardenFabricObjectOptions {
	readonly type?: "textbox";
}

export type IEcogardenFabricPolylineOptions = {
	/**
	 * Polygon points
	 */
	// eslint-disable-next-line functional/prefer-readonly-type
	points?: readonly (
		| fabric.Point
		| { readonly x: number; readonly y: number }
	)[];
	readonly type?: "polyline";
};

export interface EcogardenFabricLine
	extends fabric.Line,
		EcogardenFabricObjectOptions {}

export interface EcogardenFabricImage
	extends fabric.Image,
		EcogardenFabricObjectOptions {}

export type IEcogardenObjectOptions = {
	readonly id?: string;
	readonly subtype?: Shape;
};

export type ImplementableFabricObjects =
	| fabric.Path
	| fabric.Object
	| fabric.Group
	| EcogardenFabricObject;

type EcogardenFabricType<T extends EcogardenObjects> =
	T extends EcogardenPolygon
		? EcogardenFabricPolygon
		: T extends EcogardenTextbox
		? EcogardenFabricTextbox
		: T extends EcogardenObject
		? EcogardenFabricObject
		: T extends ecogardenObjects.EcogardenPath
		? EcogardenFabricPath
		: EcogardenFabricObject;

/**
 * Transforms EcogardenObject to fabric.Object
 */
// eslint-disable-next-line max-lines-per-function, complexity
export const toFabricObject = <T extends EcogardenObjects>(
	object: T
): Promise<EcogardenFabricObjects> => {
	if (!object.subtype) {
		log.debug("invalid shape for object", object.id, object.subtype);
		return Promise.reject(`Invalid subtype on object. (${object.subtype})`);
	}

	// Polygons
	if (object.type === "polygon") {
		if (!("points" in object)) {
			log.debug(
				`Invalid custom shape for ${object.subtype} ${object.id}. No points in object.`
			);
			return Promise.reject(
				`Invalid custom shape for ${object.subtype} ${object.id}. No points in object.`
			);
		}

		log.debug("loading polygon and fill", object.id);
		const polygon = createPolygon(object)(object.points ?? []);
		applyPolygonControls(polygon);
		return loadPatternFill()(polygon)(getFillFile(object.subtype)) as Promise<
			EcogardenFabricType<EcogardenPolygon>
		>;
	}

	// Path
	if (object.type === "path") {
		const ecogardenPath = object as ecogardenObjects.EcogardenPath;
		const pathCommands = ecogardenPath.path;
		if (pathCommands === undefined) {
			return Promise.reject(
				`Invalid path commands for path. ${object.subtype} ${object.id}.`
			);
		}
		const options: Omit<fabric.IPathOptions, "path"> = {
			...defaultPathOpts,
			...ecogardenPath,
			scaleX: ecogardenPath.scaleX ?? 1,
			scaleY: ecogardenPath.scaleY ?? 1,
			strokeWidth: pathUtil.sizeToNumber(ecogardenPath.strokeWidth),
			fill: undefined,
		};

		// @ts-ignore
		delete options.path;
		return Promise.resolve(createPath(options)(pathCommands));
	}

	if (object.type === "line") {
		if (!("x1" in object)) {
			log.debug(
				() =>
					`Invalid line for ${object.subtype} ${object.id}. No points in object.`
			);
			return Promise.reject(
				`Invalid line for ${object.subtype} ${object.id}. No points in object.`
			);
		}

		log.debug("loading polygon and fill", object.id);

		if (
			object.x1 === undefined ||
			object.y1 === undefined ||
			object.x2 === undefined ||
			object.y2 === undefined
		) {
			return Promise.reject(
				`invalid line object ${object.x1} ${object.y1} ${object.x2} ${object.y2}`
			);
		}

		const line = createLine({
			...object,
			strokeWidth: pathSizeToNumber(object.strokeWidth),
		})([object.x1, object.y1, object.x2, object.y2]);

		applyLineControls(line);

		return Promise.resolve(line);
	}

	// Textbox
	if (isTextbox(object.subtype as Shape)) {
		if (!("text" in object) || object.text === undefined) {
			return Promise.reject(
				`Invalid textbox object for ${object.subtype} ${object.id}. No text in object.`
			);
		}

		log.debug("loading textbox", object.id);
		// Confirmed that we have a textbox and textbox should be set
		const textbox = object as ecogardenObjects.EcogardenTextbox;
		const { size, text, fill, ...opts } = textbox;

		const fillOpts: { fill?: string } = {};

		// If fill is undefined we should just use the default color
		if (fill !== undefined) {
			fillOpts.fill = fill;
		}

		return Promise.resolve(
			createTextbox({ fontSize: sizeToNumber(size), ...fillOpts, ...opts })(
				text
			)
		);
	}

	if (isImage(object.subtype as Shape)) {
		// load image from indexeddb
		return loadImageDB().then((db) => {
			return loadImageFromStorage(db)(object).then((img) => {
				if (!img) {
					return Promise.reject(`Invalid image for ${object.id}.`);
				}

				setupImage(img);

				return img;
			});
		});
	}

	log.debug("loading shape", object.id);

	return loadShape(
		getShapeSVGFile(object.subtype),
		object as IEcogardenObjectOptions
	).then((shape) => {
		if (object.fill && typeof object.fill === "string") {
			// Apply accent color to shape
			convertFillColor(object.fill)(shape);
		}
		return shape;
	});
};

/**
 * Convert fill color to target fill by replacing any 1 group deep fill.
 */
export const convertFillColor =
	(fill?: string) => (fObject: EcogardenFabricObjects) => {
		if (fill) {
			if (
				fObject.type === "group" &&
				"_objects" in fObject &&
				fObject._objects.length > 0
			) {
				log.debug("converting fill for group", fill, fObject);
				// replace all fill with this fill
				fObject
					.getObjects()
					.filter(({ fill }) => fill != null)
					.forEach((o) => {
						o.set("fill", fill);
					});
			}
			if (fObject.type === "rect" && fObject.fill !== null) {
				(fObject as EcogardenFabricRect).set("fill", fill);
			}
			if (fObject.type === "circle" && fObject.fill !== null) {
				(fObject as EcogardenFabricCircle).set("fill", fill);
			}
		}
		return fObject;
	};

export const compareFill =
	(targetFill: string) => (fObject: EcogardenFabricObjects) => {
		if (targetFill) {
			if (
				fObject.type === "group" &&
				"_objects" in fObject &&
				fObject._objects.length > 0
			) {
				log.debug("comparing fill for group", targetFill, fObject);
				// replace all fill with this fill
				return fObject
					.getObjects()
					.some(({ fill }) => fill != null && fill === targetFill);
			}
			if (fObject.type === "rect" && fObject.fill !== null) {
				return fObject.fill === targetFill;
			}
			if (fObject.type === "circle" && fObject.fill !== null) {
				return fObject.fill === targetFill;
				// (fObject as EcogardenFabricCircle).set("fill", fill);
			}
		}
		return fObject;
	};

/**
 * Convert a EcogardenObject to a object of options for fabric
 * Useful in passing an ecogarden object to fabric object to update it's state
 */
// eslint-disable-next-line max-lines-per-function
export const toFabricTransformOptions = (
	object: EcogardenObjects | EcogardenFabricObjects
):
	| {
			readonly scaleX?: number | undefined;
			readonly scaleY?: number | undefined;
	  }
	| undefined => {
	if (object.type === "group") {
		const groupOptions: fabric.IGroupOptions = {
			scaleX: object.scaleX,
			scaleY: object.scaleY,
		};

		return groupOptions;
	}

	if (object.type === "polygon") {
		const polygonOptions: fabric.IPolylineOptions = {
			scaleX: object.scaleX,
			scaleY: object.scaleY,
		};

		return polygonOptions;
	}

	if (object.type === "rect") {
		const rectOptions: fabric.IRectOptions = {
			scaleX: object.scaleX,
			scaleY: object.scaleY,
		};

		return rectOptions;
	}

	if (object.type === "circle") {
		const circleOptions: fabric.ICircleOptions = {
			scaleX: object.scaleX,
			scaleY: object.scaleY,
		};

		return circleOptions;
	}

	return undefined;
};

/**
 * Transforms a list of EcogardenObject[] into fabric.Object[]
 */
export const toFabricObjects = <T extends EcogardenObjects>(
	objects: readonly T[]
): readonly Promise<Readonly<EcogardenFabricObjects>>[] => {
	return objects.map(toFabricObject);
};

export const findMatchingObjects =
	(fabricObjects: readonly fabric.Object[]) =>
	(objects: readonly EcogardenObjects[]) =>
	(
		id: string
	): readonly [
		EcogardenFabricObjects | undefined,
		EcogardenObjects | undefined
	] => {
		const ecogardenObject = ecogardenObjects.findObject(objects)(id);
		const fabricObject = findObject(fabricObjects)(id);

		return [fabricObject, ecogardenObject];
	};

/**
 * Sync the sizes for fabric object based on the ecogarden object
 */
export const syncSizes =
	(fabricObject: EcogardenFabricObjects | undefined) =>
	(object: EcogardenObjects | undefined): void => {
		if (!object) {
			return;
		}
		const options = toFabricTransformOptions(object);

		if (!options) {
			return;
		}

		fabricObject?.animate(options, {
			duration: 96,
			onChange: () => {
				fabricObject?.canvas?.requestRenderAll();
			},
		});
		fabricObject?.setCoords();
	};

/**
 * Find and reset the object size based on the saved state
 */
export const findAndSyncSizes =
	(fabricObjects: readonly EcogardenFabricObjects[]) =>
	(objects: readonly ecogardenObjects.EcogardenObjects[]) =>
	(id: string | undefined): void => {
		if (!id) {
			return;
		}

		const [fabricObject, ecogardenObject] =
			findMatchingObjects(fabricObjects)(objects)(id);

		syncSizes(fabricObject)(ecogardenObject);
	};

/**
 * Find and reset the object size based on the saved state
 */
export const findAndSyncText =
	(fabricObjects: readonly EcogardenFabricObjects[]) =>
	(objects: readonly ecogardenObjects.EcogardenObjects[]) =>
	(id: string | undefined): void => {
		if (!id) {
			return;
		}

		const [fabricObject, ecogardenObject] =
			findMatchingObjects(fabricObjects)(objects)(id);

		if (
			fabricObject?.type === "textbox" &&
			ecogardenObject?.type === "textbox"
		) {
			syncTextSize(fabricObject as EcogardenFabricTextbox)(
				ecogardenObject as EcogardenTextbox
			);
		}
	};

/**
 * Sync the sizes for fabric object based on the ecogarden object
 */
export const syncTextSize =
	(fabricObject: EcogardenFabricTextbox | undefined) =>
	(object: EcogardenTextbox | undefined): void => {
		if (!object) {
			return;
		}

		fabricObject?.animate("fontSize", sizeToNumber(object.size), {
			duration: 96,
			onChange: () => {
				fabricObject?.canvas?.requestRenderAll();
			},
		});
	};

export const findAndSyncStroke =
	(fabricObjects: readonly EcogardenFabricObjects[]) =>
	(objects: readonly ecogardenObjects.EcogardenObjects[]) =>
	// eslint-disable-next-line complexity
	(id: string | undefined): void => {
		if (!id) {
			return;
		}

		const [fabricObject, ecogardenObject] =
			findMatchingObjects(fabricObjects)(objects)(id);

		if (
			(fabricObject?.type === "line" && ecogardenObject?.type === "line") ||
			(fabricObject?.type === "path" && ecogardenObject?.type === "path")
		) {
			syncStroke(
				fabricObject as EcogardenFabricPath | EcogardenFabricLine | undefined
			)(ecogardenObject);
		}
	};

export const syncStroke =
	(fabricObject: EcogardenFabricPath | EcogardenFabricLine | undefined) =>
	(object: EcogardenPath | EcogardenLine | undefined): void => {
		if (!object) {
			return;
		}

		fabricObject?.animate("stroke", object.stroke ?? "rgb(0,0,0)", {
			duration: 96,
			onChange: () => {
				fabricObject?.canvas?.requestRenderAll();
			},
		});
	};

export type FabricGroupObject = EcogardenFabricObject;

/**
 * Remove objects from an EcogardenFabricObject list
 */
export const removeObjects = (
	objects: readonly EcogardenFabricObject[]
): readonly Promise<string | undefined>[] =>
	objects
		.filter((id) => id !== undefined)
		.map((object) => {
			// eslint-disable-next-line compat/compat
			return new Promise((resolve) => {
				const id = object.id;
				if (object.canvas) {
					object.canvas.fxRemove(object, {
						onComplete: () => {
							object.canvas?.requestRenderAll();
							resolve(id);
						},
					});
				} else {
					resolve(id);
				}

				return id;
			});
		});

/**
 * Moves fabric.Object in Direction for Offset
 */
export const moveObject =
	(o: Readonly<fabric.Object>) =>
	(direction: Direction) =>
	(offset: number): void => {
		o.set(calculateOffsetMovement(direction)(offset)(o));
		o.setCoords();
	};

// /**
//  * Remove selected objects from the canvas
//  */
export const removeActiveObjects = (
	canvas: Readonly<fabric.Canvas>
): Promise<readonly (string | undefined)[]> => {
	const objs = canvas.getActiveObjects() as readonly EcogardenFabricObject[];
	canvas.discardActiveObject();
	// eslint-disable-next-line compat/compat
	return Promise.all(removeObjects(objs)).then((ids) => {
		canvas.requestRenderAll();

		return ids;
	});
};

/**
 * Find fabric object in fabric objects list
 */
export const findObject =
	(objects: readonly fabric.Object[] | undefined) =>
	(id: string): fabric.Object | undefined => {
		if (!objects) {
			return undefined;
		}

		if (!id) {
			return undefined;
		}

		return objects.find((object) => object.id === id);
	};

type BoundingClientRect = {
	readonly x: number;
	readonly y: number;
	readonly width: number;
	readonly height: number;
	readonly top: number;
	readonly right: number;
	readonly bottom: number;
	readonly left: number;
};

type VirtualElement = {
	readonly getBoundingClientRect: () => BoundingClientRect;
	readonly clientWidth: number;
	readonly clientHeight: number;
};

/**
 * Transforms a Bounding box into a VirtualElement for simulating the canvas
 */
export const objectToVirtualElement = ({
	left,
	top,
	width,
	height,
}: Bounding): VirtualElement => {
	return {
		getBoundingClientRect: (): BoundingClientRect => {
			return {
				x: left,
				y: top,
				width: width,
				height: height,
				top: top,
				right: left + width,
				bottom: top + height,
				left: left,
			};
		},
		// eslint-disable-next-line fp/no-get-set
		get clientWidth(): number {
			return width;
		},
		// eslint-disable-next-line fp/no-get-set
		get clientHeight(): number {
			return height;
		},
	};
};

export const findSize = (object: {
	readonly width?: number;
	readonly height?: number;
}): number => {
	const { width, height } = object;

	if (!width || !height) {
		return 0;
	}

	return width / PIXELS_PER_FEET;
};

/**
 * Get selection text from object/group
 */
export const selectionText = (
	object: EcogardenFabricObject | fabric.ActiveSelection
): string => {
	if (!object.type || ("subtype" in object && !object.subtype)) {
		return "";
	}

	if ("subtype" in object && object.subtype) {
		return capitalize(dashesToSpaces(object.subtype));
	}

	if ("getObjects" in object && object.type === "activeSelection") {
		return `${object.getObjects().length} selected`;
	}

	return "";
};

/**
 * Add locked to a string
 * Used in tooltip overlay
 */
export const addLock =
	(object: Readonly<EcogardenFabricObject>) =>
	(string: string): string => {
		return `${string}${object.selectable ? "" : " 🔒 Locked"}`;
	};

/**
 * Resize object on canvas by size (in pixels)
 */
export const resizeObject =
	(object: Readonly<EcogardenFabricObject>) =>
	(sizeInPixels: number): void => {
		const scale = sizeInPixelsToScale(
			object.type,
			object.subtype
		)(sizeInPixels);

		object.set("scaleX", scale);
		object.set("scaleY", scale);
		object.setCoords();
	};

/**
 * Animate the change to scales of objects in fabric
 */
export const animateScaleChange =
	(_sizeType: "width" | "depth" | "size") =>
	(scales: { readonly scaleX?: number; readonly scaleY?: number }) =>
	(object: EcogardenFabricObjects) =>
	(): void => {
		object.animate(scales, {
			duration: 44,
			onChange: () => object.canvas?.requestRenderAll(),
			onComplete: () => {
				object.setCoords();
			},
		});
	};

// Find the offset of the object inside a group
export const objectPositionInGroup = (
	obj: EcogardenFabricObject & { width: number; height: number }
): { top: number; left: number } => {
	const matrix = obj.calcTransformMatrix();
	// 2. choose the point you want, for example center, center. (selection uses center origin)
	// const point = { x: -obj.width / 2, y: obj.height / 2 };
	const point = { x: 0, y: 0 };

	// 3. transform the point
	const pointOnCanvas = fabric.util.transformPoint(point, matrix);

	return { top: pointOnCanvas.y, left: pointOnCanvas.x };
};

// Find the placement of an object on the canvas, accounting for being in a group, like selection.
export const objectPositionOnCanvas = (obj: EcogardenFabricObjects) => {
	if (obj.group && obj.width && obj.height) {
		return objectPositionInGroup(
			obj as EcogardenFabricObjects & { width: number; height: number }
		);
	}

	return { top: obj.top, left: obj.left };
};

export const updateFillToNewColor =
	(newColor: string) =>
	(obj: fabric.Object): fabric.Object => {
		obj.set({ fill: newColor });

		return obj;
	};

export const createLine =
	(options: fabric.ILineOptions) => (points: number[]) => {
		const line = new fabric.Line(points, {
			selectable: false,
			subtype: "line",
			perPixelTargetFind: true,
			originX: "center",
			originY: "center",
			lockScalingX: true,
			lockScalingY: true,
			...options,
		});

		return line;
	};

/**
 * Find the size of the stroke on an object, which is applied to
 * line, polygon, polyline, rect, circle, etc
 */
export const getStrokePadding = (target: fabric.Object): number => {
	if (!target.strokeWidth) {
		return 0;
	}
	if (target.strokeUniform && target.scaleX) {
		return target.strokeWidth / target.scaleX;
	}

	return target.strokeWidth;
};
