/* eslint-disable max-lines-per-function */
import { Dispatch } from "redux";
import { addObject, sortObjects } from "../../actions/objects";
import { getFillFile, sortCanvasObjects } from "../../shapes";
import { applyControls as applyPolygonControls } from "../controls/fabric/polygon";
import { loadPatternFill } from "../fabric";
import { toEcogardenObject } from "../objects";
import { fabric } from "fabric";
import { EcogardenFabricPolygon } from "./objects";

export const finishEditingPolygon =
  (canvas: Readonly<fabric.Canvas>) =>
  (polygon: Readonly<EcogardenFabricPolygon>) =>
  (dispatch: Dispatch) => {
    // console.log("boundingbox", polygon.getBoundingRect());
    // console.log("points", polygon.points);
    // const box = polygon?.points?.reduce(boundariesReducer, []);
    // console.log(box);

    // if (box && box.length === 4) {
    //   canvas.add(
    //     new fabric.Rect({
    //       left: box[0],
    //       top: box[1],
    //       width: box[2] - box[0],
    //       height: box[3] - box[1],
    //     })
    //   );
    // }

    // Empty polygons should not be saved. They are not even a shell of themselves.
    if (polygon.points?.length && polygon.points?.length < 3) {
      // We have a remaining polygon that isn't more than 0 points, we should remove it
      if (polygon.points.length > 0) {
        canvas.remove(polygon);
        canvas.requestRenderAll();
      }
      return;
    }

    const object = toEcogardenObject(polygon);
    dispatch(addObject(object));

    polygon.set("strokeWidth", 0);
    polygon._setPositionDimensions({});
    polygon._projectStrokeOnPoints();

    canvas._objects.sort(sortCanvasObjects);
    // return reloadPolygon(canvas)(polygon).then(() => {
    //   const object = toEcogardenObject(polygon);
    //   dispatch(addObject(object));

    //   const afterCleared = (): void => {
    //     canvas._objects.sort(sortCanvasObjects);
    //     dispatch(sortObjects());
    //     canvas.off("before:selection:cleared", afterCleared);
    //     canvas.requestRenderAll();
    //   };

    //   canvas.on("before:selection:cleared", afterCleared);
    // });
  };

/**
 * Add a new point at the pointer to the polygon.
 * Will set as active object
 * Will download and add a new pattern fill
 */
export const addPolygonPoint =
  (canvas: Readonly<fabric.Canvas>) =>
  (polygon: Readonly<EcogardenFabricPolygon>) =>
  // eslint-disable-next-line functional/prefer-readonly-type, complexity
  (pointer: { x: number; y: number }) => {
    // eslint-disable-next-line functional/prefer-readonly-type
    polygon.set("points", [
      ...(polygon.points ?? []),
      { x: pointer.x, y: pointer.y },
    ]);

    polygon._setPositionDimensions({});
    // polygon._projectStrokeOnPoints();

    // Adding new points requires us to trigger dirty state to re-render
    polygon.set("dirty", true);
    polygon.setCoords();

    polygon.canvas?.requestRenderAll();

    // If the polygon is not on the canvas, lets add it
    if (!polygon.canvas && polygon.subtype) {
      canvas.add(polygon);
      applyPolygonControls(polygon);

      loadPatternFill()(polygon)(getFillFile(polygon.subtype)).then(() =>
        canvas.requestRenderAll()
      );
    }
  };
