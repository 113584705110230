/* eslint-disable max-lines */
/* eslint-disable max-lines-per-function */
import { fabric } from "fabric";
import { getFillFile, getShapeSVGFile, sortCanvasObjects } from "../shapes";
import { applyControls } from "./controls/fabric/polygon";
import type {
  EcogardenFabricObject,
  EcogardenFabricPolygon,
  FabricGroupObject,
  IEcogardenObjectOptions,
  ImplementableFabricObjects,
} from "./fabric/objects";
import { Point } from "./vector";
import { middle, PointerPoint } from "./dom";
import { hexToHsla, hexToRgba, percentageBetween } from "./utils";
import { boundariesReducer } from "./boundaries";
import log from "./log";

export type FabricEventHandler = (opt: fabric.IEvent) => void;

export type PanZoom = {
  readonly pan: { readonly x: number; readonly y: number };
  readonly zoom: number;
};

/**
 * Convert SVG text to a fabric object
 */
export const svgToFabricObject =
  (options: IEcogardenObjectOptions) =>
  (svgText: string): Promise<EcogardenFabricObject> =>
    new Promise((resolve) => {
      fabric.loadSVGFromString(svgText, (objects, svgOptions) => {
        const assembled = fabric.util.groupSVGElements(objects, {
          ...svgOptions,
          ...options,
        });

        // Set the options after they are grouped or resolved
        // These should be being applied in the grouping function
        // but does not apply for non-groups.
        assembled.set(options);

        // Use stroke uniform to eliminate stroke scaling with the object
        applyOptionsToAllObjects(assembled)({
          strokeUniform: true,
        });
        resolve(assembled as EcogardenFabricObject);
      });
    });

/**
 * Apply an option to all objects, likely in a group.
 * Ex applying strokeUniform to all strokes in the group.
 */
const applyOptionsToAllObjects =
  (object: fabric.Object) =>
  <T>(options: T): void => {
    if (object instanceof fabric.Group) {
      object.forEachObject((object_) => {
        applyOptionsToAllObjects(object_)<T>(options);
      });
    } else {
      object.setOptions(options);
    }
  };

type UrlString = string;

/**
 * svgUrl url to svg to load into fabric
 * groupObjectOptions object applied to svg group. Good for positioning the object after loading.
 */
export const loadSVG = (
  svgUrl: UrlString,
  groupObjectOptions: IEcogardenObjectOptions
): Promise<FabricGroupObject> => {
  return fetch(svgUrl)
    .then((resp) => resp.text())
    .then(svgToFabricObject(groupObjectOptions));
};

/**
 * fabric.Canvas
 */
export interface EcogardenCanvas extends fabric.Canvas {
  _objects: EcogardenFabricObject[];
  getObjects: (type?: string) => EcogardenFabricObject[];
  readonly forEachObject: (
    callback: (
      object: EcogardenFabricObject,
      index: number,
      array: EcogardenFabricObject[]
    ) => void
  ) => EcogardenCanvas;
}

export interface SortableCanvas extends fabric.Canvas {
  readonly sortObjects: (
    compareFunction: (a: fabric.Object, b: fabric.Object) => number
  ) => void;
}

/**
 * Add shape to canvas
 */
export const addShapeToCanvas = (
  canvas: fabric.Canvas,
  shape: fabric.Object
): fabric.Canvas => {
  canvas.add(shape);
  canvas._objects.sort(sortCanvasObjects);
  canvas.requestRenderAll();

  return canvas;
};

/**
 * Add object to canvas
 */
export const addShape = async (
  canvas: SortableCanvas,
  options: IEcogardenObjectOptions & fabric.IObjectOptions
): Promise<EcogardenFabricObject> => {
  if (!options.type || !options.subtype) {
    throw new Error("Could not add shape. Missing properties.");
  }

  return loadSVG(getShapeSVGFile(options.subtype), options).then((shape) => {
    addShapeToCanvas(canvas, shape);

    return shape;
  });
};

export const calculateShadows = (
  width: number,
  height: number
): fabric.IShadowOptions => {
  const scaleX = percentageBetween(width, 600);
  const scaleY = percentageBetween(height, 600);

  const scaleXExp = Math.exp(scaleX);
  const scaleYExp = Math.exp(scaleY);

  return {
    color: `rgba(0,0,0,${scaleXExp})`,
    offsetX: scaleXExp * (1 + Math.log(scaleX)),
    offsetY: scaleYExp * (1 + Math.log(scaleY)),
    blur: scaleXExp * 3,
  };
};

export const setFadeOpacity = (object: fabric.Object): void => {
  object.animate("opacity", 0.5, {
    onChange: () => object.canvas?.requestRenderAll(),
    duration: 48,
    onComplete: () => object.canvas?.requestRenderAll(),
  });
};

export const setFullOpacity = (object: fabric.Object): void => {
  object.animate("opacity", 1, {
    onChange: () => object.canvas?.requestRenderAll(),
    duration: 48,
    onComplete: () => object.canvas?.requestRenderAll(),
  });
};

export const fadeOutExcept = (
  objs: readonly EcogardenFabricObject[],
  ids: readonly string[]
): readonly EcogardenFabricObject[] => {
  objs.forEach((object) => {
    if (!object.id) {
      return;
    }

    ids.includes(object.id) ? setFullOpacity(object) : setFadeOpacity(object);
  });
  return objs;
};

export const convertFillHexToHsla = (o: fabric.Object): fabric.Object => {
  if (typeof o.fill === "string" && o.fill.slice(0, 1) === "#") {
    const [h, s, l, a] = hexToHsla(o.fill);
    o.set({
      fill: `hsla(${h}, ${s}%, ${l}%, ${a} )`,
    });
  }

  return o;
};

/**
 * Convert object hexadecimal fill to RGBA
 */
export const convertFillHexToRgba = (o: fabric.Object): fabric.Object => {
  if (typeof o.fill === "string" && o.fill.slice(0, 1) === "#") {
    const [r, g, b, a] = hexToRgba(o.fill);
    o.set({
      fill: `rgba(${r}, ${g}, ${b}, ${a})`,
    });
  }

  return o;
};

/**
 * Convert all group fills to HSLA
 */
export const groupFillToHSLA = (shape: fabric.Object): void => {
  if (shape instanceof fabric.Group) {
    shape.getObjects().map(convertFillHexToHsla);
  } else {
    convertFillHexToHsla(shape);
  }
};

/**
 * Add shadows to object
 * {@deprecated} Removed set('shadow') from fabric 4.0
 */
export const addShadows = (shape: fabric.Object): void => {
  shape.set(
    "shadow",
    new fabric.Shadow(
      calculateShadows(shape.getScaledWidth(), shape.getScaledHeight())
    )
  );
};

/**
 * Apply default filters to objects
 */
export const applyDefaultFilters = (
  shape: ImplementableFabricObjects
): void => {
  [groupFillToHSLA].map((filter) => {
    if (shape instanceof fabric.Group) {
      filter(shape);
    }
  });
};

/**
 * fill an object with an image pattern
 *
 * Timeout default to a large amount
 * because large designs can take some time to load
 */
export const loadPatternFill =
  (timeout = 15000) =>
  <T extends EcogardenFabricObject>(object: T) =>
  (url: string): Promise<T> => {
    // eslint-disable-next-line compat/compat
    return new Promise((resolve, reject) => {
      const timeoutId = setTimeout(() => {
        reject(`Could not load pattern from url:  ${url}`);
      }, timeout);

      fabric.util.loadImage(url, (img) => {
        object.set("fill", new fabric.Pattern({ source: img }));
        log.debug("loaded pattern", img);
        clearTimeout(timeoutId);
        resolve(object);
      });
    });
  };

// /*
//    * TO load and apply the image pattern as a fill
//    */
//   return function loadPattern(url: string): Promise<T> {
//     // eslint-disable-next-line compat/compat
//     return new Promise((resolve) => {
//       fetch(url)
//         .then((resp) => {
//           return resp.blob();
//         })
//         .then((blob) => {
//           const img = document.createElement("img");
//           img.src = URL.createObjectURL(blob);
//           // wait for image to be loaded before adding it to the object
//           img.onload = () => {
//             object.set("fill", new fabric.Pattern({ source: img }));
//             resolve(object);
//           };
//         });
//     });
//   };
// }

// // eslint-disable-next-line @typescript-eslint/prefer-readonly-parameter-types
// export function reloadPolygon(canvas: fabric.Canvas) {
//   return function reload(
//     poly: EcogardenFabricPolygon
//   ): Promise<EcogardenFabricPolygon> {
//     canvas.remove(poly);

//     // eslint-disable-next-line compat/compat
//     return new Promise((resolve, reject) => {
//       // eslint-disable-next-line max-lines-per-function, complexity
//       fabric.Polygon.fromObject(poly, ({ points, ...object }) => {
//         // delete object.top;
//         // delete object.left;
//         const boundaries = poly?.points?.reduce(boundariesReducer, []);

//         if (!boundaries || boundaries.length !== 4) {
//           return;
//         }

//         const newPoly = new fabric.Polygon([...(poly.points ?? [])], {
//           id: poly.id,
//           subtype: poly.subtype,
//           left: boundaries[1] ?? 0,
//           top: boundaries[0] ?? 0,
//           width: boundaries[2] - boundaries[0],
//           height: boundaries[3] - boundaries[1],
//           ...object,

//           objectCaching: false,
//           hasBorders: false,
//           strokeWidth: 0,
//           perPixelTargetFind: true,
//         }) as EcogardenFabricPolygon;

//         if (!newPoly.subtype) {
//           reject("Invalid subtype to reload pattern fill");
//           return;
//         }

//         // TODO: Hacky solution to reload the fill pattern after remaking it
//         loadPatternFill()(newPoly)(getFillFile(newPoly.subtype));

//         // TODO May not be necessary to set the coords to fix the dimensions after recreating
//         poly.setCoords();

//         // Need controls to exist before adding to the canvas.
//         // newPoly.set("controls", fabric.Object.prototype.controls);
//         applyControls(newPoly);

//         canvas.add(newPoly);

//         canvas.setActiveObject(newPoly);

//         newPoly._setPositionDimensions({ left: true, top: true });
//         // newPoly._projectStrokeOnPoints();

//         canvas.requestRenderAll();

//         resolve(newPoly);
//       });
//     });
//   };
// }

export const toFabricPoint = ({ x, y }: Readonly<Point>): fabric.Point =>
  new fabric.Point(x, y);

type Coords = {
  readonly tl: Point;
  readonly tr: Point;
  readonly br: Point;
  readonly bl: Point;
};

export const arrayFromCoords = (coords: Coords): readonly fabric.Point[] => {
  return [
    new fabric.Point(coords.tl.x, coords.tl.y),
    new fabric.Point(coords.tr.x, coords.tr.y),
    new fabric.Point(coords.br.x, coords.br.y),
    new fabric.Point(coords.bl.x, coords.bl.y),
  ];
};

type Pointer = {
  target: HTMLElement | null;
} & PointerPoint &
  Partial<TouchPointer>;

type TouchPointer = { changedTouches: { clientX: number; clientY: number }[] };

/**
 * Get the center of a pointer
 * * mouse pointer,
 * * center of 2 touch
 */
export const getPointerCenter = ({
  clientX,
  clientY,
  changedTouches,
  target,
}: Pointer): Point => {
  if (!target) {
    return { x: clientX, y: clientY };
  }

  const scroll = fabric.util.getScrollLeftTop(target);

  // Need to account for touches to get the center of the touches
  if (
    changedTouches &&
    changedTouches.length === 2 &&
    changedTouches[0] &&
    changedTouches[1]
  ) {
    const mid = middle(changedTouches[0])(changedTouches[1]);
    return {
      x: mid.x + scroll.left,
      y: mid.y + scroll.top,
    };
  }

  return {
    x: clientX + scroll.left,
    y: clientY + scroll.top,
  };
};

export const getCanvasPointerCenter =
  (canvas: fabric.Canvas & { _offset?: { left: number; top: number } }) =>
  (pointer: Readonly<Pointer>): Point => {
    const center = getPointerCenter(pointer);

    // take this center and transform it based on the viewportTransform in the canvas
    // const offset = fabric.util.getElementOffset(pointer.target)

    // Check for _offset in fabric Canvas or check pointer target for offset
    const offset =
      canvas._offset ??
      (pointer.target !== null
        ? fabric.util.getElementOffset(pointer.target)
        : { left: 0, top: 0 });

    return offsetPoint(offset)(center);
  };

const scalePoint =
  (scaling: number) =>
  (point: Readonly<Point>): Readonly<Point> => {
    return {
      x: point.x / scaling,
      y: point.y / scaling,
    };
  };

type BoxBounds = {
  width: number;
  height: number;
};

/**
 * Scaling ratio between 2 bounds
 */
type ScaledBounds = {
  width: number;
  height: number;
};

/**
 * Find the scaling ratio between 2 Bounds
 */
export const scaleBounds =
  (a: Readonly<BoxBounds>) =>
  (b: Readonly<BoxBounds>): Readonly<ScaledBounds> => {
    if (b.width === 0 || b.height === 0) {
      return { width: 1, height: 1 };
    }

    return {
      width: a.width / b.width,
      height: a.height / b.height,
    };
  };

/**
 * Apply bounds scaling (width, height) to the point
 */
export const applyBoundsScaleToPoint =
  (bound: Readonly<ScaledBounds>) =>
  (point: Readonly<Point>): Readonly<Point> => {
    return {
      x: point.x * bound.width,
      y: point.y * bound.height,
    };
  };

/**
 * Offset
 */
type Offset = {
  left: number;
  top: number;
};

/**
 * Transforms a point by the offset
 */
export const offsetPoint =
  (offset: Readonly<Offset>) =>
  (point: Readonly<Point>): Readonly<Point> => {
    return {
      x: point.x - offset.left,
      y: point.y - offset.top,
    };
  };

/**
 * Find the center point of a boundary
 */
export const centerPoint = ({
  left,
  top,
  width,
  height,
}: Readonly<{
  top: number;
  left: number;
  width: number;
  height: number;
}>): fabric.Point => {
  const x = left + width / 2;
  const y = top + height / 2;

  return new fabric.Point(x, y);
};
