/* eslint-disable functional/no-throw-statement */
/* eslint-disable fp/no-throw */
import { fabric } from "fabric";
import { StateWithHistory } from "redux-undo";
import type { RootState } from "../configureStore";
import { applyDefaultFilters, EcogardenCanvas } from "../fabric";
import log from "../log";
import type { EcogardenObject } from "../objects";
import type { BoxCoord } from "./boundaries";
// import { calculatePanZoom, findObjectsBoundingBox, toBox } from "./boundaries";
import type { EcogardenFabricObject, EcogardenFabricObjects } from "./objects";
import { toFabricObjects } from "./objects";

/** Selectable option to fabric canvas */
export type SelectableFabricCanvas = EcogardenCanvas & {
  // Mutable selectable
  // eslint-disable-next-line functional/prefer-readonly-type
  selectable?: boolean;
};

export const createCanvas =
  (options: fabric.ICanvasOptions) =>
  (reference: string | HTMLCanvasElement): fabric.Canvas => {
    return new fabric.Canvas(reference, {
      ...defaultCanvasOpts,
      ...options,
    });
  };

export const createStaticCanvas =
  (options: fabric.ICanvasOptions) =>
  (reference: string | HTMLCanvasElement): fabric.StaticCanvas => {
    return new fabric.StaticCanvas(reference, {
      ...defaultCanvasOpts,
      ...options,
    });
  };

const defaultCanvasOpts: fabric.ICanvasOptions = {
  allowTouchScrolling: true,
  renderOnAddRemove: false,
  preserveObjectStacking: true,
  enableRetinaScaling: true,
  centeredScaling: true,
  // stateful: true,
  // Use CSS custom properties and add alpha channel
  selectionColor:
    getComputedStyle(document.documentElement).getPropertyValue(
      "--accent-bg-color"
    ) + "80",
};

type BoundingRectangle = {
  readonly left: number;
  readonly top: number;
  readonly width: number;
  readonly height: number;
};

/**
 * Transform BoundingRectangle to a BoxCoord
 */
export const toCoords = (rect: BoundingRectangle): BoxCoord => ({
  tl: { x: rect.left, y: rect.top },
  tr: { x: rect.left + rect.width, y: rect.top },
  bl: { x: rect.left, y: rect.top + rect.height },
  br: { x: rect.left + rect.width, y: rect.top + rect.height },
});

/**
 * Load all the objects, including file assets (svg, png fill)
 * Used when loading the state from storage or a loaded design file
 */
export const loadObjects = (
  objects:
    | readonly EcogardenObject[]
    | StateWithHistory<readonly EcogardenObject[]>
): Promise<readonly Readonly<EcogardenFabricObject>[]> =>
  // eslint-disable-next-line compat/compat
  Promise.all(
    "present" in objects
      ? toFabricObjects(objects.present)
      : toFabricObjects(objects)
  ).then((objs) => {
    log.debug(`${objs.length} objects loaded, apply filters`);
    return objs.map((o) => {
      if (!o.subtype) {
        return o;
      }

      // if (findShapeTools(o.subtype).includes("Shape")) {
      // }

      applyDefaultFilters(o);

      return o;
    });
  });

// https://mariusschulz.com/articles/keyof-and-lookup-types-in-typescrip
const prop = <T, K extends keyof T>(obj: T, key: K): T[K] => {
  return obj[key];
};

// eslint-disable-next-line max-lines-per-function, complexity
export const loadCanvas = async (
  canvas: Readonly<fabric.Canvas>,
  state: Pick<RootState, "viewport" | "objects"> | unknown
): Promise<readonly EcogardenFabricObjects[]> => {
  if (!state || state === null) {
    throw new Error("Invalid design. invalid state");
  }

  if (typeof state != "object") {
    throw new TypeError("Invalid design. invalid state");
  }

  log.debug("loading canvas, state valid");

  // if (state && !("viewport" in state)) {
  //   // set default viewport instead of erroring
  //   // eslint-disable-next-line fp/no-mutation
  //   (state as Pick<RootState, "viewport">).viewport = [1, 0, 0, 1, 0, 0];
  // }

  const viewport = prop(state as Pick<RootState, "viewport">, "viewport");

  // eslint-disable-next-line functional/prefer-readonly-type
  canvas.setViewportTransform([...viewport]);

  log.trace("Updated canvas viewport to", viewport);

  if (state && !("objects" in state)) {
    // throw new Error("Invalid design. objects invalid");
    log.debug("invalid design, objects invalid");
    return Promise.resolve([]);
  }

  const objects = prop(state as Pick<RootState, "objects">, "objects");

  return loadObjects(objects).then((objs) => {
    objs.map((o) => {
      canvas.add(o);
    });

    canvas.requestRenderAll();
    return objs;
  });
};

/**
 * Set active selection on the canvas
 */
export const setSelection =
  (canvas: EcogardenCanvas) =>
  (objects: readonly EcogardenFabricObjects[]) =>
  (e?: Event): fabric.ActiveSelection => {
    canvas.discardActiveObject(e);

    // remove objects
    // canvas.remove(...objects)

    const selection = new fabric.ActiveSelection([...objects], { canvas });

    canvas.setActiveObject(selection);
    canvas.renderAll();

    return selection;
  };
