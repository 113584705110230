import { fabric } from "fabric";
import { v4 as uuidv4 } from "uuid";
import {
  EcogardenFabricPolygon,
  EcogardenFabricTextbox,
  IEcogardenObjectOptions,
} from "../../lib/fabric/objects";
import { applyControls as applyPolygonControls } from "../../lib/controls/fabric/polygon";
import { dropShadow } from "./shadows";

// Static runtime value for the colorscheme
// This will prevent it from changing if the user changes their colorscheme, but
// will load properly on reload
// also affects how designs are exported as this is "hardcoded" and won't update when creating exports
// this causes a problem with how to represent font color and text shadows
const prefersDarkColorscheme = () =>
  "matchMedia" in window &&
  window.matchMedia("(prefers-color-scheme: dark)").matches;

export const createTextbox =
  (options: fabric.TextOptions) =>
  (label: string): EcogardenFabricTextbox => {
    const textbox = new fabric.Textbox(label, {
      id: uuidv4(),
      fontSize: 36,
      fontFamily: "Kalam,Inter V, Inter, sans-serif",
      subtype: "label",
      width: 150,
      textAlign: "center",
      // Alternative to shadows
      // textBackgroundColor: "hsla(170, 100%, 100%, 0.5)",
      shadow: dropShadow(),
      // stroke: prefersDarkColorscheme ? "white" : "black",
      fill: prefersDarkColorscheme() ? "white" : "black",
      originX: "center",
      originY: "center",
      hiddenTextareaContainer: document.querySelector("#hiddenTextarea"),
      editingBorderColor: prefersDarkColorscheme()
        ? "#878b5a"
        : "hsla(170, 100%, 100%, 0.5)",
      selectionBackgroundColor: prefersDarkColorscheme()
        ? "#14140d2e"
        : "hsla(170, 100%, 100%, 0.5)",
      selectionColor: prefersDarkColorscheme() ? "#eaff0022" : "#ffd70050",
      ...options,
    }) as EcogardenFabricTextbox;

    return textbox;
  };

export const createPolygon =
  (options: IEcogardenObjectOptions & fabric.IPolylineOptions) =>
  (
    points: readonly (
      | { readonly x: number; readonly y: number }
      | fabric.Point
    )[]
  ): EcogardenFabricPolygon => {
    const polygon = new fabric.Polygon([...points], {
      originX: "center",
      originY: "center",
      scaleX: 1,
      scaleY: 1,
      angle: 0,
      hasBorders: false,
      strokeUniform: true,
      perPixelTargetFind: true,
      editingBorderColor: prefersDarkColorscheme()
        ? "#878b5a"
        : "hsla(170, 100%, 100%, 0.5)",
      ...options,
    } as IEcogardenObjectOptions & fabric.IPolylineOptions) as EcogardenFabricPolygon;

    return polygon;
  };
