import { findShapeLayer, LayersState, Shape, SHAPES } from "../../shapes";
import { EcogardenCanvas } from "../fabric";
import log from "../log";
import { EcogardenFabricObject } from "./objects";

export type ObjectLayerFilter = (
  layers: LayersState
) => (
  element: EcogardenFabricObject,
  index?: number,
  array?: readonly EcogardenFabricObject[]
) => void;

/**
 * Set lock and visible settings to the canvas based on the layers state.
 */
export const layerLockVisibleFilter: ObjectLayerFilter =
  (layers) => (object) => {
    if (!SHAPES.includes(object.subtype as Shape)) {
      return;
    }

    const layer = findShapeLayer(object.subtype);
    if (!layer || !layers[layer]) {
      return;
    }

    object.set("selectable", layers[layer]?.lock ? false : true);
    // object.set("evented", layers[layer]?.lock ? false : true);
    object.set("evented", layers[layer]?.visible ? true : false);
    // FIXME: currently setting opacity to 1 to force opacity
    // back to "normal" after fading out when selecting
    // object.set("opacity", 1);

    object.set("opacity", layers[layer]?.visible ? 1 : 0.3);
    // object.set("visible", layers[layer].visible);
    object.canvas?.requestRenderAll();
  };

// eslint-disable-next-line max-lines-per-function
export const fillOpacityFilter: ObjectLayerFilter = (layers) => (object) => {
  if (!SHAPES.includes(object.subtype as Shape)) {
    return;
  }

  const layer = findShapeLayer(object.subtype as Shape);
  if (!layer || !layers[layer]) {
    return;
  }

  if ("_objects" in object) {
    (object as fabric.Group)._objects.forEach((o) => {
      if (!o.fill) {
        return;
      }

      if (!(typeof o.fill === "string")) {
        // console.log("is not a string fill, not supported", o.fill);
        return;
      }

      if (o.fill?.slice(0, 1) === "#") {
        // console.log("is a hex color, not supported");
        return;
      }

      const last = o.fill?.lastIndexOf(",");

      const first = o.fill?.slice(0, Math.max(0, last));
      // console.log("first", first);
      const fill = `${first}, ${layers[layer]?.visible ? "0.95" : "0.2"})`;
      // console.log("setting fill", fill);
      o.set("fill", fill);
    });
  } else {
    // console.log("fill", object.fill);
    // object.set(
    //   "fill",
    //   `hsla(170,20%,20%, ${layers[layer].visible ? "1" : "0.1"}`
    // );
  }
};

/**
 * Apply the lock/visible layer filters to the canvas
 */
export const applyLayerFilters =
  (canvas: EcogardenCanvas) =>
  (layers: LayersState): void => {
    canvas.forEachObject((object) => {
      // Not an object we control
      if (!object.id) {
        return;
      }

      // If the object has become locked after being selected, discard selection.
      if (object === canvas.getActiveObject()) {
        // is locked?
        const layer = findShapeLayer(object.subtype);
        if (layer && layers[layer]?.lock) {
          log.debug(
            () => `deselecting ${object.id?.substring(0, 8)} in layer ${layer}`
          );
          canvas.discardActiveObject();
        }
      }
      layerLockVisibleFilter(layers)(object);
      fillOpacityFilter(layers)(object);
      canvas.requestRenderAll();
    });
    canvas.requestRenderAll();
  };
