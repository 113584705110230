/* eslint-disable fp/no-mutation */
/* eslint-disable functional/no-let */
/* eslint-disable fp/no-let */
// https://github.com/fabricjs/fabric.js/pull/7178
import { fabric } from "fabric";
import { useCallback, useEffect, useRef } from "react";
import log from "../log";
// import log from "../log";
import { createCanvas } from "./canvas";

// let _init: ((canvas: fabric.Canvas) => void) | undefined = undefined;

// eslint-disable-next-line max-lines-per-function
export const useCanvas = (
  init?: (canvas: fabric.Canvas) => void,
  canvas?: fabric.Canvas
): readonly [
  React.MutableRefObject<fabric.Canvas | undefined>,
  (ref: HTMLCanvasElement | null) => void
] => {
  const canvasRef = useRef<fabric.Canvas | undefined>(canvas);
  const elementRef = useRef<HTMLCanvasElement | null>(null);

  const setRef = useCallback(
    // eslint-disable-next-line complexity
    (ref: HTMLCanvasElement | null) => {
      elementRef.current = ref;

      // set/clear ref
      if (!elementRef.current || !ref) {
        canvasRef.current = undefined;
        log.debug("clearing canvas ref");
        return;
      }

      if (canvas) {
        canvasRef.current = canvas;
        log.info("setting current canvas to passed canvas", canvasRef);
        return;
      }

      const fabricCanvas = createCanvas({})(elementRef.current);

      if (!fabricCanvas) {
        log.info("Invalid canvas to create", fabricCanvas);
        return;
      }

      canvasRef.current = fabricCanvas;

      // invoke callback
      // eslint-disable-next-line babel/no-unused-expressions
      init && init(fabricCanvas);
    },
    [init, canvas]
  );

  useEffect(() => {
    // disposer
    return () => {
      // we avoid unwanted disposing by doing so only if element ref is unavailable
      if (!elementRef.current) {
        canvasRef.current?.dispose();
        canvasRef.current = undefined;
      }
    };
  }, [init, canvas]);

  return [canvasRef, setRef];
};
