/* eslint-disable complexity */
/* eslint-disable max-lines-per-function */

import { fabric } from "fabric";
import { constrain, objectsBoundaries } from "../boundaries";
import { EcogardenObjects } from "../objects";
import { createStaticCanvas, loadObjects } from "./canvas";

// eslint-disable-next-line max-lines-per-function
export const generateImage = async (
	objects: readonly EcogardenObjects[]
): Promise<string> => {
	const exportCanvas = createStaticCanvas({})(document.createElement("canvas"));

	// eslint-disable-next-line max-lines-per-function
	return loadObjects(objects).then((objs) => {
		objs.forEach((object) => {
			exportCanvas.add(object);
		});

		const objects = exportCanvas.getObjects();

		// For images we want the design to be exported at 1 scale.
		exportCanvas.setZoom(1);

		const [tlx, tly, brx, bry] = objectsBoundaries(
			objects.filter(({ id }) => id !== undefined)
		) as readonly [number, number, number, number];

		// TODO: make padding configurable
		const padding = 50;
		const box = {
			// Offset the box by the padding
			left: tlx - padding,
			top: tly - padding,
			// We need to account for padding on both sides
			width: brx - tlx + padding * 2,
			height: bry - tly + padding * 2,
		};

		// canvas needs to hold
		const canvasBox = {
			left: 0,
			top: 0,
			width: box.width,
			height: box.height,
		};

		const [x, y, w, h] = constrain(canvasBox)(box);

		exportCanvas.setDimensions({
			width: w,
			height: h,
		});
		exportCanvas.absolutePan({ x, y });
		exportCanvas.renderAll();

		// addPlantListToExport({ padding,  canvasPadding })(exportCanvas)

		return exportCanvas.toDataURL({
			withoutShadow: true,
			multiplier: 1,
		});
	});
};

export const addPlantListToExport =
	({
		padding,
		canvasPadding,
	}: {
		readonly padding: number;
		readonly canvasPadding: number;
	}) =>
	(exportCanvas: fabric.Canvas) => {
		// Disabled for now, used to allow printing with plant lists shown on the side
		if (exportCanvas.width && exportCanvas.height) {
			const plantListWidth = 480;
			exportCanvas.setWidth(
				exportCanvas.width + plantListWidth + canvasPadding * 2
			);
			exportCanvas.setHeight(exportCanvas.height + canvasPadding * 2);

			exportCanvas.relativePan(new fabric.Point(padding, padding));

			if (exportCanvas.viewportTransform) {
				const strokeWidth = 4;

				const background = new fabric.Rect({
					fill: "white",
					stroke: "black",
					strokeWidth,
					strokeLineCap: "square",
					width: exportCanvas.width + strokeWidth * 2,
					height: exportCanvas.height - strokeWidth * 2,

					left: 0 - exportCanvas.viewportTransform[4] + strokeWidth / 2,
					top: 0 - exportCanvas.viewportTransform[5] + strokeWidth / 2,
				});

				exportCanvas.add(background);

				background.sendToBack();

				// now we want to add our plantlist
				const plantlistBackground = new fabric.Rect({
					width: plantListWidth - strokeWidth * 2,
					height: exportCanvas.height - strokeWidth * 2,
					fill: "white",
					stroke: "black",
					strokeWidth: strokeWidth,
					left:
						exportCanvas.width -
						exportCanvas.viewportTransform[4] -
						plantListWidth,
					top: 0 - exportCanvas.viewportTransform[5],
				});

				const plantListHeader = new fabric.Text("Plant List", {
					fontFamily: "Inter, sans-serif",
					fontWeight: 900,
					fontSize: 40,
					left:
						exportCanvas.width -
						exportCanvas.viewportTransform[4] -
						plantListWidth +
						padding,
					top: 0 - exportCanvas.viewportTransform[5] + padding,
					fill: "black",
				});

				const headerHeight = plantListHeader.height
					? plantListHeader.height + padding
					: 75;

				const plantList = new fabric.Text(
					`3x Perrenials
		10x Shrubs
		10x Trees`,
					{
						fontFamily: "Inter, sans-serif",
						fontSize: 22,
						left:
							exportCanvas.width -
							exportCanvas.viewportTransform[4] -
							plantListWidth +
							padding,
						top: 0 - exportCanvas.viewportTransform[5] + padding + headerHeight,
					}
				);

				const footer = new fabric.Text(
					`Built with Ecogarden
		ecogarden.design`,
					{
						fontSize: 22,

						fontFamily: "Inter, sans-serif",
						left:
							exportCanvas.width -
							exportCanvas.viewportTransform[4] -
							plantListWidth +
							padding,
						top:
							exportCanvas.height - exportCanvas.viewportTransform[5] - padding,
					}
				);

				if (footer.height && footer.top) {
					footer.set("top", footer.top - footer.height);
				}

				exportCanvas.add(
					plantlistBackground,
					plantListHeader,
					plantList,
					footer
				);

				// fabric.util.applyTransformToObject(
				//   plantlistBackground,
				//   exportCanvas.viewportTransform
				// );
				// fabric.util.applyTransformToObject(
				//   plantList,
				//   exportCanvas.viewportTransform
				// );
			}
		}
	};
